import React, { useEffect, useState } from 'react'
import Button from "../../extras/Button";
import Input from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { editData, objectToFormData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { DangerRight } from '../../api/toastServices';

import {
  galleryAdd,
  galleryUpdate,
} from "../../../redux/slice/gallerySlice";
import { imageURL } from '../../../util/config';

const GalleryDialogue = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
      setImagePath(`${imageURL}/dialogueData?.image}`);
    }
  }, [dialogueData]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePath(URL.createObjectURL(file)); // Preview the image
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const addGallery = submitData(e);

    if (addGallery) {

      const formData = objectToFormData(addGallery);

      try {
        let response;
        if (dialogueData) {

          formData.append('_method', 'patch');
          const payload = { formData, galleryId: dialogueData.id };
          response = await dispatch(galleryUpdate(payload)).unwrap();
        } else {

          response = await dispatch(galleryAdd(formData)).unwrap();
        }
        response.status ? dispatch(closeDialog()) : DangerRight(response.message);
      } catch (err) {
        console.log("err", err);
        DangerRight(err.message);
      }
    }
  };


  return (

    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">Gallery Dialogue</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="expertForm">
                <div className="row align-items-start formBody">
                  <div className="col-12 col-md-6">
                    <Input
                      type={`text`}
                      id={`name`}
                      name={`name`}
                      label={`Name`}
                      placeholder={`Name`}
                      errorMessage={`Enter Name`}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Input
                      type="file"
                      id="image"
                      name="image"
                      label="Image"
                      errorMessage="Image is required"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="row  formFooter">
                  <div className="col-12 text-end m0">
                    <Button className={`bg-gray text-light`} text={`Cancel`} type={`button`} onClick={() => dispatch(closeDialog())} />
                    <Button
                      type={`submit`}
                      className={`bg-theme text-light m10-left`}
                      text={`Submit`}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryDialogue;
