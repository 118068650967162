import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";

const initialState = {
  gallery: [],
  isLoading: false,
  isSkeleton: false,
  total: null
}

export const getAllGallery = createAsyncThunk("admin/gallery/getAllGallery", async () => {
  return apiInstanceFetch.get("admin/gallery/getAllGallery");
})

export const galleryAdd = createAsyncThunk("admin/gallery", async (payload) => {
  return apiInstance.post("admin/gallery", payload);
})

export const galleryUpdate = createAsyncThunk("admin/gallery/update", async (payload) => {

  return apiInstance.post(`admin/gallery/update/${payload?.galleryId}`, payload?.formData);
})

export const galleryDelete = createAsyncThunk("admin/gallery/delete", async (id) => {

  return apiInstance.delete(`admin/gallery/delete/${id}`)
})

// export const galleryStatus = createAsyncThunk("admin/gallery/status", async (id) => {

//   return apiInstance.put(`admin/gallery/status?gallery_id=${id}`)
// })


const gallerySlice = createSlice({
  name: "gallerySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getAllGallery.pending, (state, action) => {
      state.isSkeleton = true;
    })

    builder.addCase(getAllGallery.fulfilled, (state, action) => {
      state.total = action.payload.total
      state.gallery = action.payload.galleries;
      state.isSkeleton = false;
    })

    builder.addCase(getAllGallery.rejected, (state, action) => {
      state.isSkeleton = false;
    })

    builder.addCase(galleryAdd.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(galleryAdd.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.gallery.unshift(action.payload.gallery);

        Success("Gallery Add Successfully")
      }
      state.isLoading = false;
    });

    builder.addCase(galleryAdd.rejected, (state, action) => {

      state.isLoading = false;
    });


    builder.addCase(galleryUpdate.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(galleryUpdate.fulfilled, (state, action) => {

      if (action.payload.status) {
        const galleryInx = state.gallery.findIndex((gallery) => gallery.id === action.payload.gallery.id);
        if (galleryInx !== -1) {
          state.gallery[galleryInx] = { ...state.gallery[galleryInx], ...action.payload.gallery };
        }
      }
      state.isLoading = false;
      Success("Gallery Update Successfully")

    });

    builder.addCase(galleryUpdate.rejected, (state, action) => {

      state.isLoading = false;
    });

    builder.addCase(galleryDelete.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(galleryDelete.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.gallery = state.gallery.filter((gallery) => gallery.id !== action.meta.arg);
        Success("Gallery Delete Successfully")
      }
      state.isLoading = false;

    })
    builder.addCase(galleryDelete.rejected, (state, action) => {
      state.isLoading = false;
    })

  }
})
export default gallerySlice.reducer